(function($) {

  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  //smooth scrolling
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });

  $('#nav-icon').click(function() {
    $('.side-nav-container').toggleClass('side-nav-active');
  });

  $('#side-nav-close').click(function() {
    $('.side-nav-container').removeClass('side-nav-active');
  });

  $('#search-icon').click(function() {
    $('.search-bar').toggleClass('search-bar-active');
  });

  $('#search-close').click(function() {
    $('.search-bar').removeClass('search-bar-active');
  });


  $( ".side-nav .mobile-dropdown-toggle" ).click(function() {
    console.log('Clicked');
    $( this ).next('.dropdown-menu').toggleClass( 'open-menu' );
  });

  var lastScroll = 0;
  $('.mobile-header_secondary').addClass('active');
  $(window).scroll(function(){
      setTimeout(function() { //give them a second to finish scrolling before doing a check
          var scroll = $(window).scrollTop();
          if (scroll > lastScroll + 30) {
              $(".mobile-header_secondary").removeClass("active");
          } else if (scroll < lastScroll - 30) {
              $(".mobile-header_secondary").addClass("active");
          }
          lastScroll = scroll;
      }, 1000);
  });


function new_map( $el ) {
  
  // var
  var $markers = $el.find('.marker');
  
  
  // vars
  var args = {
    zoom    : 16,
    center    : new google.maps.LatLng(0, 0),
    mapTypeId : google.maps.MapTypeId.ROADMAP
  };
  
  
  // create map           
  var map = new google.maps.Map( $el[0], args);
  
  
  // add a markers reference
  map.markers = [];
  
  
  // add markers
  $markers.each(function(){
    
      add_marker( $(this), map );
    
  });
  
  
  // center map
  center_map( map );
  
  
  // return
  return map;
  
}

function add_marker( $marker, map ) {

  // var
  var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

  // create marker
  var marker = new google.maps.Marker({
    position  : latlng,
    map     : map
  });

  // add to array
  map.markers.push( marker );

  // if marker contains HTML, add it to an infoWindow
  if( $marker.html() )
  {
    // create info window
    var infowindow = new google.maps.InfoWindow({
      content   : $marker.html()
    });

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function() {

      infowindow.open( map, marker );

    });
  }

}


function center_map( map ) {

  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each( map.markers, function( i, marker ){

    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

    bounds.extend( latlng );

  });

  // only 1 marker?
  if( map.markers.length == 1 )
  {
    // set center of map
      map.setCenter( bounds.getCenter() );
      map.setZoom( 16 );
  }
  else
  {
    // fit to bounds
    map.fitBounds( bounds );
  }

}

var map = null;

$(document).ready(function(){

  $('.acf-map').each(function(){

    // create map
    map = new_map( $(this) );

  });

});


})(jQuery);
